import { Button } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import React from 'react'

import { useScopedIntl } from '../../../hooks'
import { CenterData, UserData } from '../../../requests'
import {
  ColumnFixedType,
  DatacAvatar,
  DatacIcon,
  DatacLoading,
  DatacOption,
  DatacTooltip,
  scopedIntlType
} from '../../common'

dayjs.extend(relativeTime)

interface ContactUserCellProps {
  name: string
  user: UserData
  showStatus: boolean
  onResend?: () => void
  isResending: boolean
  showSsoBadge: boolean
}

const ContactUserCell: React.VFC<ContactUserCellProps> = ({
  name,
  user,
  showStatus,
  onResend,
  isResending,
  showSsoBadge
}) => {
  const intlUsers = useScopedIntl('users_table')
  const resendLink = (
    <div
      className={`datac-link${isResending ? ' datac-link--disabled' : ''}`}
      onClick={isResending ? undefined : onResend}
    >
      {intlUsers('resend')}
      <DatacLoading isLoading={isResending} transparent />
    </div>
  )

  const tags = (
    <>
      {showSsoBadge && user.sso && (
        <div className="users-table__name__tag users-table__name__tag--sso">{intlUsers('sso')}</div>
      )}
      {user.locked && (
        <div className="users-table__name__tag users-table__name__tag--locked">{intlUsers('user_locked')}</div>
      )}
      {showStatus && user.pending && name !== '-' && (
        <div className="users-table__name__tag users-table__name__tag--unconfirmed">{intlUsers('unconfirmed')}</div>
      )}
    </>
  )

  return (
    <div className="users-table__name">
      <div className="users-table__name__avatar">
        {name !== '-' && !user.pending && <DatacAvatar fullName={name} type="square" />}
        {user.pending && (!name || name === '-') && <DatacIcon name="refresh" />}
        {name !== '-' && user.pending && <DatacIcon name="alertTriangle" />}
      </div>
      <div className="users-table__name__content">
        <div className="users-table__name__full-name">
          {name !== '-' && (
            <>
              {renderWithTooltip(name)}
              {tags}
            </>
          )}
        </div>
        <div className="users-table__name__email">
          {showStatus && user.pending && name !== '-' && resendLink}
          <span>{user.email}</span>
          {name === '-' && tags}
        </div>
        {showStatus && user.pending && (!name || name === '-') && (
          <div className="users-table__name__status">
            {resendLink}
            <span>{intlUsers('invitation_pending')}</span>
          </div>
        )}
      </div>
    </div>
  )
}

const renderLastLogin = (lastLogin: Dayjs) =>
  (lastLogin && <DatacTooltip title={lastLogin.format('DD MMM yyyy, HH:mm')}>{lastLogin.fromNow()}</DatacTooltip>) ||
  '-'

const renderWithTooltip = (content: string) => (
  <DatacTooltip title={content}>
    <span>{content}</span>
  </DatacTooltip>
)

type Role = {
  name: string
  color: string
}

const RoleCell: React.VFC<{ role: Role }> = ({ role }) => {
  return (
    <span className="users-table__user-role user-role">
      <span className="user-role__indicator" style={{ backgroundColor: role.color }} />
      {role.name}
    </span>
  )
}

interface LockUserCellProps {
  onLock?: () => void
  onUnlock?: () => void
}
const LockUserCell: React.VFC<LockUserCellProps> = ({ onLock, onUnlock }) => {
  const intlUsers = useScopedIntl('users_table')
  if (onLock) {
    return (
      <DatacTooltip title={intlUsers('lock_user')}>
        <Button className="datac-button--icon-only" onClick={onLock}>
          <DatacIcon name="lock" />
        </Button>
      </DatacTooltip>
    )
  }
  if (onUnlock) {
    return (
      <DatacTooltip title={intlUsers('unlock_user')}>
        <Button className="datac-button--icon-only" onClick={onUnlock}>
          <DatacIcon name="unlock" />
        </Button>
      </DatacTooltip>
    )
  }
  return null
}

interface DeleteUserProps {
  onDelete?: () => void
}
const DeleteUserCell: React.VFC<DeleteUserProps> = ({ onDelete }) =>
  !!onDelete && (
    <Button className="datac-button--icon-only" onClick={onDelete}>
      <DatacIcon name="trash" />
    </Button>
  )

interface EditUserCellProps {
  onEdit?: () => void
  hasAccessToAll: boolean
}
const EditUserCell: React.VFC<EditUserCellProps> = ({ onEdit, hasAccessToAll }) =>
  !!onEdit &&
  !hasAccessToAll && (
    <Button className="datac-button--icon-only" onClick={onEdit}>
      <DatacIcon name="edit" />
    </Button>
  )

interface CentersCellProps {
  centers: CenterData[]
  onUserCenters: () => void
  hasAccessToAll: boolean
}

const CentersCell: React.VFC<CentersCellProps> = ({ centers, onUserCenters, hasAccessToAll }) => {
  const intlUsers = useScopedIntl('users_table')
  const intl = useScopedIntl('')

  if (!centers?.length && !hasAccessToAll) return <> - </>

  const getCentersCountLabel = () => {
    if (hasAccessToAll) return intl('common.all')
    if (centers.length === 1) return intlUsers('center_count_one')
    return intlUsers('center_count', { count: centers.length })
  }

  return (
    <Button onClick={onUserCenters} type="primary" className="users-table__centers-button">
      {getCentersCountLabel()}
      <DatacIcon name="chevronRight" raw />
    </Button>
  )
}

interface GetColumnsOptions {
  columnNames: {
    name: string
    lastLogin: string
    company: string
    role: string
    centers: string
    actions: string
  }
  onDelete?: (user: UserData) => void
  onEdit?: (user: UserData) => void
  onUnlock?: (user: UserData) => void
  onLock?: (user: UserData) => void
  onResend?: (user: UserData) => void
  isUserEditable?: (user: UserData) => boolean
  isUserDeletable?: (user: UserData) => boolean
  isUserUnlockable?: (user: UserData) => boolean
  isUserLockable?: (user: UserData) => boolean
  isResending?: (user: UserData) => boolean
  onUserCenters?: (user: UserData) => void
  showCenters: boolean
  showStatus: boolean
  showLock: boolean
  showRole: boolean
  showSsoBadge: boolean
}
export const getColumns = ({
  columnNames,
  onDelete,
  onEdit,
  onUnlock,
  onLock,
  onResend,
  isUserEditable = () => true,
  isUserDeletable = () => true,
  isUserUnlockable = () => false,
  isUserLockable = () => false,
  isResending = () => false,
  onUserCenters = () => false,
  showCenters,
  showStatus,
  showLock,
  showRole,
  showSsoBadge
}: GetColumnsOptions) => {
  return [
    {
      title: columnNames.name,
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: '25%',
      sorter: true,
      fixed: 'left' as ColumnFixedType,
      render: (name: string, user: UserData) => (
        <ContactUserCell
          name={name}
          user={user}
          showStatus={showStatus}
          onResend={onResend && user.pending ? () => onResend(user) : undefined}
          isResending={isResending(user)}
          showSsoBadge={showSsoBadge}
        />
      )
    },
    {
      title: columnNames.company,
      dataIndex: 'company',
      key: 'company',
      ellipsis: true,
      sorter: true,
      width: '20%',
      render: renderWithTooltip
    },
    showRole
      ? {
          title: columnNames.role,
          dataIndex: 'role',
          key: 'role',
          ellipsis: true,
          sorter: true,
          width: '20%',
          render: (role: Role) => <RoleCell role={role} />
        }
      : { width: '0px' },
    {
      title: columnNames.lastLogin,
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      ellipsis: true,
      sorter: false,
      width: '15%',
      render: (lastLogin: Dayjs) => renderLastLogin(lastLogin)
    },
    showCenters
      ? {
          title: columnNames.centers,
          dataIndex: 'centers',
          key: 'centers',
          width: '20rem',
          render: (centers: CenterData[], user: UserData) => (
            <CentersCell
              hasAccessToAll={user.hasAccessToAll}
              centers={centers}
              onUserCenters={() => onUserCenters(user)}
            />
          )
        }
      : { width: '0px' },
    {
      className: 'users-table__action-column',
      title: columnNames.actions,
      dataIndex: 'id',
      key: 'actions',
      width: '16rem',
      render: (_: string, user: UserData) => (
        <div className="users-table__actions">
          {showLock && (
            <LockUserCell
              onLock={onLock && isUserLockable(user) ? () => onLock(user) : undefined}
              onUnlock={onUnlock && isUserUnlockable(user) ? () => onUnlock(user) : undefined}
            />
          )}
          <EditUserCell
            onEdit={onEdit && isUserEditable(user) ? () => onEdit(user) : undefined}
            hasAccessToAll={user.hasAccessToAll}
          />
          <DeleteUserCell onDelete={onDelete && isUserDeletable(user) ? () => onDelete(user) : undefined} />
        </div>
      ),
      fixed: 'right' as ColumnFixedType
    }
  ]
}

export const searchAndFilterOptions = (
  rolesOptions: DatacOption[],
  centersOptions: DatacOption[],
  intl: scopedIntlType
) => {
  const intlSearchBar = (id: string) => intl(`users_table.search_bar.${id}`)

  const filters = [
    {
      label: intlSearchBar('roles.header'),
      name: 'role',
      options: rolesOptions
    }
  ]

  if (centersOptions)
    filters.push({
      label: intlSearchBar('center.header'),
      name: 'center',
      options: centersOptions
    })

  return {
    searchInputPlaceholder: intlSearchBar('search.placeholder'),
    filters
  }
}
