import './UsersTable.less'

import { Modal } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { CenterData, RoleData, SorterOrder, UserData } from '../../../requests'
import { TableRecord } from '../../../utils'
import { UserContext } from '../../auth'
import { DatacIcon, DatacOption, DatacPaginationItem, DatacTable, DatacTableSearchAndFilters } from '../../common'
import { getColumns, searchAndFilterOptions } from './UsersTableConfig'

export const rolesDataToOptions = (roles: RoleData[], onlyUsed: boolean) =>
  roles.reduce((acc, curr) => {
    if (onlyUsed && !curr.usersCount && !curr.invitationsCount) return acc
    return [...acc, { value: curr.id, label: curr.name }]
  }, [] as DatacOption[])

interface Props {
  onDelete?: (user: UserData) => void
  onEdit?: (user: UserData) => void
  onLock?: (user: UserData) => void
  onUnlock?: (user: UserData) => void
  onResend?: (user: UserData) => void
  currentPage: number
  onPageChange: (page: number) => void
  totalCount: number
  disabled?: boolean
  pageSize: number
  users: UserData[]
  onSorterChange: (tableSorter: { field: keyof UserData; order: SorterOrder }) => void
  isUserEditable?: (user: UserData) => boolean
  isUserDeletable?: (user: UserData) => boolean
  isUserUnlockable?: (user: UserData) => boolean
  isUserLockable?: (user: UserData) => boolean
  isResending?: (user: UserData) => boolean
  isStudyTable?: boolean
  isModuleTable?: boolean
  onFiltersChange: (filters: Record<string, string[]>) => void
  filters: Record<string, string[]>
  onSearchChange: (value: string) => void
  search: string
  usedRoles?: DatacOption[]
  centers?: CenterData[]
  options?: React.ReactNode
  scrollX?: number
}

export const UsersTable: React.FC<Props> = ({
  onDelete,
  onEdit,
  onLock,
  onUnlock,
  onResend,
  currentPage,
  onPageChange,
  totalCount,
  disabled = false,
  pageSize,
  users,
  onSorterChange,
  isUserEditable,
  isUserDeletable,
  isUserUnlockable,
  isUserLockable,
  isResending,
  isStudyTable,
  isModuleTable,
  onFiltersChange,
  filters,
  onSearchChange,
  search,
  usedRoles = [],
  centers,
  options,
  scrollX
}) => {
  const [studyUsers, setStudyUsers] = useState<TableRecord<UserData>[]>(null)
  const [centerPopupUser, setCenterPopupUser] = useState<UserData>(null)
  const intl = useScopedIntl('')
  const intlUsers = useScopedIntl('users_table')
  const { user: currentUser } = useContext(UserContext)

  useEffect(() => {
    setStudyUsers(users ? users.map(createRecord) : null)
  }, [users])

  const createRecord = (userData: UserData) => ({
    ...userData,
    key: userData.type ? `${userData.type}-${userData.id}` : userData.id
  })

  const columns = getColumns({
    columnNames: {
      name: intlUsers('column.user'),
      company: intlUsers('column.company'),
      role: isStudyTable ? intlUsers('column.study_role') : intlUsers('column.platform_role'),
      lastLogin: intlUsers('column.last_login'),
      centers: intl('common.centers'),
      actions: intl('common.actions')
    },
    onDelete,
    onEdit,
    onUnlock,
    onLock,
    onResend,
    isUserEditable,
    isUserDeletable,
    isUserUnlockable,
    isUserLockable,
    isResending,
    onUserCenters: (user: UserData) => setCenterPopupUser(user),
    showCenters:
      isStudyTable ||
      isModuleTable ||
      currentUser.isSubjectRepositoryEnabled ||
      currentUser.isPaymentsEnabled ||
      currentUser.isCalendarEnabled,
    showStatus: !isStudyTable && !isModuleTable,
    showLock: !isStudyTable && !isModuleTable,
    showRole: !isModuleTable,
    showSsoBadge: !isStudyTable
  })

  const getUserCenters = (user: UserData) => {
    if (user?.hasAccessToAll) return centers
    return user?.centers || []
  }

  const centersOptions = centers?.map(center => ({
    value: center.id,
    label: center.abbreviation,
    sublabel: center.code
  }))

  return (
    <div className="users-table">
      <DatacTableSearchAndFilters
        onSearchChange={value => onSearchChange(value)}
        onFiltersChange={onFiltersChange}
        isSearching={disabled}
        searchAndFilterOptions={searchAndFilterOptions(usedRoles, centersOptions, intl)}
        initialSearchValue={search}
        initialFilterValues={filters}
        options={options}
      />
      <DatacTable
        dataSource={studyUsers}
        columns={columns}
        rowSelection={undefined}
        scroll={{ x: scrollX || 800 }}
        pagination={{
          current: currentPage,
          position: ['bottomCenter'],
          itemRender: DatacPaginationItem,
          onChange: onPageChange,
          total: totalCount,
          showSizeChanger: false,
          disabled,
          pageSize
        }}
        onChange={(_, __, sorter) => onSorterChange(sorter as { field: keyof UserData; order: SorterOrder })}
      />
      <Modal
        open={!!centerPopupUser}
        closable={false}
        footer={null}
        className="datac-modal users-table__centers-modal"
        centered
        width="auto"
      >
        <div className="datac-modal__header">
          <div className="datac-modal__title">
            <DatacIcon name="mapPin" type="blue" size="xbig" />
            <h2>
              {intlUsers('user_centers')}:<small>{centerPopupUser?.name}</small>
            </h2>
          </div>
          <DatacIcon
            className="datac-modal__close-icon"
            name="x"
            type="grey"
            onClick={() => setCenterPopupUser(null)}
          />
        </div>
        <div className="datac-modal__body">
          {getUserCenters(centerPopupUser).map(({ abbreviation, name, code }, index) => (
            <div key={abbreviation}>
              {`${name} - ${abbreviation}${code ? `(${code})` : ''}`}
              {index + 1 < centerPopupUser.centers.length ? ',' : ''}
            </div>
          ))}
        </div>
      </Modal>
    </div>
  )
}
