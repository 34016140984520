import './SettingsUsersContent.less'

import { Button } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import DatacError from '../../../assets/images/datac-error.svg'
import { useScopedIntl } from '../../../hooks'
import {
  AccountType,
  AclAction,
  AclFeature,
  CenterData,
  SorterOrder,
  UserData,
  UserType,
  UsersSorter,
  fetchCenters as fetchCentersRequest,
  fetchRoles,
  fetchUsers,
  resendRegistrationConfirmMessage
} from '../../../requests'
import { UserContext } from '../../auth'
import { DatacIcon, DatacMessage, DatacOption, DatacTitle } from '../../common'
import { UsersTable, rolesDataToOptions } from '../../shared/UsersTable'
import { SettingsLayout } from '../SettingsLayout'
import { SettingsUsersDeleteModal } from './SettingsUsersDeleteModal'
import { SettingsUsersEditModal } from './SettingsUsersEditModal'
import { SettingsUsersInviteModal } from './SettingsUsersInviteModal'
import { SettingsUsersLockModal } from './SettingsUsersLockModal'
import { SettingsUsersUnlockModal } from './SettingsUsersUnlockModal'

const defaultSorter: UsersSorter = { field: 'name', order: SorterOrder.Ascend }

const pageSize = 20

export const SettingsUsersContent: React.FC = () => {
  const intlUsers = useScopedIntl('settings.users')
  const intl = useScopedIntl('')
  const [users, setUsers] = useState<UserData[]>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [allCount, setAllCount] = useState(0)
  const [isFetchingUsers, setIsFetchingUsers] = useState(false)
  const [ongoingResendUserId, setOngoingIsResendUserId] = useState<string>(null)
  const [sorter, setSorter] = useState<UsersSorter>(null)
  const [filters, setFilters] = useState<Record<string, string[]>>()
  const [search, setSearch] = useState<string>(null)
  const [isInviteModalOpened, setIsInviteModalOpened] = useState(false)
  const [userToEdit, setUserToEdit] = useState<UserData>(null)
  const [userToDelete, setUserToDelete] = useState<UserData>(null)
  const [userToUnlock, setUserToUnlock] = useState<UserData>(null)
  const [userToLock, setUserToLock] = useState<UserData>(null)
  const [availableRoles, setAvailableRoles] = useState<DatacOption[]>([])
  const [usedRoles, setUsedRoles] = useState<DatacOption[]>([])
  const { user: currentUser } = useContext(UserContext)
  const userCan = currentUser.canDo(AclFeature.GlobalUsersManagement)
  const [availableCenters, setAvailableCenters] = useState<CenterData[]>([])

  useEffect(() => {
    fetchAvailableRoles()
    fetchCenters()
  }, [])

  useEffect(() => {
    if (availableCenters.length) fetchUsersPage(0, sorter, search, filters)
  }, [search, filters, availableCenters])

  const onPageChange = (page: number) => {
    fetchUsersPage(page - 1, sorter, search, filters)
  }

  const fetchCenters = () => {
    fetchCentersRequest(
      {
        options: {
          limit: 500, // TODO: instead of such a big limit we should add additional calls on search and center abbreviation in users object
          offset: 0,
          sorter: { field: 'abbreviation', order: SorterOrder.Ascend }
        }
      },
      {
        onSuccess: ({ centers }) => setAvailableCenters(centers),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }
  const fetchUsersPage = (page: number, sorter: UsersSorter, search: string, filters: Record<string, string[]>) => {
    setIsFetchingUsers(true)
    return fetchUsers(
      { options: { limit: pageSize, offset: page * pageSize, sorter: sorter || defaultSorter, search, filters } },
      {
        onSuccess: ({ users, allUsersCount }) => {
          setUsers(
            users.map(u => ({
              ...u,
              centers: u.centers?.map(userCenter => availableCenters.find(center => center.id === userCenter.id) || {})
            }))
          )
          setAllCount(allUsersCount)
          setIsFetchingUsers(false)
          setCurrentPage(page + 1)
        },
        onRequestError: code => {
          setIsFetchingUsers(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const onSorterChange = (tableSorter: { field: keyof UserData; order: SorterOrder }) => {
    const sorterChanged =
      (!sorter && tableSorter.order) ||
      (sorter && (tableSorter.field !== sorter?.field || tableSorter.order !== sorter?.order))
    if (sorterChanged) {
      const newSorter = tableSorter.order
        ? {
            field: tableSorter.field,
            order: tableSorter.order === 'ascend' ? SorterOrder.Ascend : SorterOrder.Descend
          }
        : null
      fetchUsersPage(currentPage - 1, newSorter, search, filters)
      setSorter(newSorter)
    }
  }

  const refreshUsersList = () => {
    fetchAvailableRoles()
    fetchUsersPage(currentPage - 1, sorter, search, filters)
  }

  const findUserByIdAndType = (user: UserData) => users.find(u => u.type === user.type && u.id === user.id)

  const fetchAvailableRoles = () => {
    return fetchRoles(
      {},
      {
        onSuccess: ({ roles }) => {
          setAvailableRoles(rolesDataToOptions(roles, false))
          setUsedRoles(rolesDataToOptions(roles, true))
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const onResend = (user: UserData) => {
    setOngoingIsResendUserId(user.id)
    resendRegistrationConfirmMessage(
      { email: user.email, accountType: AccountType.User },
      {
        onSuccess: () => {
          setOngoingIsResendUserId(null)
          DatacMessage.success(intlUsers('resend_success.title'), intlUsers('resend_success.description'))
        },
        onRequestError: code => {
          setOngoingIsResendUserId(null)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const onFiltersChange = (filters: Record<string, string[]>) => {
    setFilters(filters)
  }

  return (
    <SettingsLayout>
      <div className="settings-users-content">
        <div className="settings-users-content__header">
          <DatacTitle type="h2">{intlUsers('title')}</DatacTitle>
          <div className="settings-users-content__header-controls">
            {userCan(AclAction.Invite) && (
              <Button
                className="settings-users-content__header-button settings-users-content__invite-button"
                type="primary"
                size="large"
                onClick={() => setIsInviteModalOpened(true)}
              >
                <DatacIcon type="white" name="plus" size="big" /> {intlUsers('invite_button')}
              </Button>
            )}
          </div>
        </div>
        {!!users && (
          <UsersTable
            users={users}
            currentPage={currentPage}
            onPageChange={onPageChange}
            totalCount={allCount}
            pageSize={pageSize}
            disabled={isFetchingUsers}
            onSorterChange={onSorterChange}
            onEdit={user => setUserToEdit(findUserByIdAndType(user))}
            onDelete={user => setUserToDelete(findUserByIdAndType(user))}
            onUnlock={user => setUserToUnlock(findUserByIdAndType(user))}
            onLock={user => setUserToLock(findUserByIdAndType(user))}
            onResend={onResend}
            isResending={user => ongoingResendUserId === user.id}
            isUserEditable={user => !user.pending && userCan(AclAction.Edit)}
            isUserUnlockable={user =>
              user.locked && user.type === UserType.User && !user.pending && userCan(AclAction.Unblock)
            }
            isUserDeletable={() => userCan(AclAction.Delete)}
            isUserLockable={user =>
              user.type === UserType.User && !user.pending && !user.locked && userCan(AclAction.Block)
            }
            onFiltersChange={onFiltersChange}
            filters={filters}
            onSearchChange={setSearch}
            search={search}
            usedRoles={usedRoles}
          />
        )}
        {!users && !availableCenters.length && !isFetchingUsers && (
          <div className="settings-users-content__no-centers-message">
            <div>
              <DatacError />
              {intlUsers('no_centers_message')}
            </div>
          </div>
        )}
      </div>
      {userCan(AclAction.Invite) && (
        <SettingsUsersInviteModal
          isOpened={isInviteModalOpened}
          onClose={() => setIsInviteModalOpened(false)}
          onInvitationSent={refreshUsersList}
          availableRoles={availableRoles}
          availableCenters={availableCenters}
        />
      )}
      {userCan(AclAction.Edit) && (
        <SettingsUsersEditModal
          isOpened={!!userToEdit}
          userToEdit={userToEdit}
          onClose={() => setUserToEdit(null)}
          onUserEdited={refreshUsersList}
          availableRoles={availableRoles}
          availableCenters={availableCenters}
        />
      )}
      {userCan(AclAction.Delete) && (
        <SettingsUsersDeleteModal
          isOpened={!!userToDelete}
          userToDelete={userToDelete}
          onClose={() => setUserToDelete(null)}
          onUserDeleted={refreshUsersList}
        />
      )}
      {userCan(AclAction.Unblock) && (
        <SettingsUsersUnlockModal
          isOpened={!!userToUnlock}
          userToUnlock={userToUnlock}
          onClose={() => setUserToUnlock(null)}
          onUserUnlocked={refreshUsersList}
        />
      )}
      {userCan(AclAction.Edit) && (
        <SettingsUsersLockModal
          isOpened={!!userToLock}
          userToLock={userToLock}
          onClose={() => setUserToLock(null)}
          onUserLocked={refreshUsersList}
        />
      )}
    </SettingsLayout>
  )
}
